import type { ProductData } from "@planetadeleste/vue-mc-shopaholic";
import { Product } from "@planetadeleste/vue-mc-shopaholic";
import type { OfferData, ProductGwData } from "@planetadeleste/vue-mc-gw";
import { chain, filter, first, get, isNil } from "lodash";
import dayjs from "dayjs";

class ProductHelper {
  private readonly _model: Product;

  constructor(obModel: Product | ProductData | Partial<ProductData>) {
    this._model = obModel instanceof Product ? obModel : new Product(obModel);
  }

  get product(): Product {
    return this._model;
  }

  get gwProduct(): Partial<ProductGwData> | undefined {
    if (!this.product) {
      return undefined;
    }

    return this.product.get("product_gw");
  }

  get taxTypeId(): number | undefined {
    return this.gwProduct ? this.gwProduct.taxtype_id : undefined;
  }

  get offerCollection(): Partial<OfferData>[] {
    return this.product && this.product.offers.length
      ? (this.product.get("offers", []) as Partial<OfferData>[])
      : [];
  }

  get offer(): Partial<OfferData> | undefined {
    if (!this.product || !this.product.offers.length) {
      return undefined;
    }

    const obOfferCollect = this.offerCollection;

    if (obOfferCollect.length === 1) {
      return first(obOfferCollect);
    }

    const now = dayjs();
    return chain(obOfferCollect)
      .filter((obData) => {
        return (
          !!obData.price_value &&
          obData.price_value > 0 &&
          !!obData.price_valid_at &&
          now.isSameOrAfter(obData.price_valid_at)
        );
      })
      .orderBy("price_valid_at", "desc")
      .first()
      .value();
  }

  get isTemporary(): boolean {
    if (!this.product || !this.offerCollection.length) {
      return false;
    }

    const obRegExp = new RegExp(/^(PROV) [0-9]+\.[0-9]\.[A-Z]{3}$/);
    const arOfferList = filter(this.offerCollection, (obOffer) => {
      return obOffer.name ? obRegExp.test(obOffer.name) : false;
    });

    return arOfferList.length >= 1;
  }

  get isRounding(): boolean {
    if (!this.product || !this.offerCollection.length) {
      return false;
    }

    const arOfferList = filter(this.offerCollection, (obOffer) => {
      const sValue = obOffer.name?.toLowerCase();
      return sValue ? sValue.indexOf("redondeo") >= 0 : false;
    });

    return arOfferList.length >= 1;
  }

  getOfferByListId(iPriceListId: number): Partial<OfferData> | undefined {
    if (!this.product || !this.product.offers.length) {
      return undefined;
    }

    const now = dayjs();
    return chain(this.offerCollection)
      .filter((obData) => {
        const iOfferPriceListId = get(obData, "price_list_id");
        return (
          (isNil(iOfferPriceListId) || iOfferPriceListId == iPriceListId) &&
          (isNil(obData.price_valid_at) ||
            (!!obData.price_value &&
              obData.price_value > 0 &&
              !!obData.price_valid_at &&
              now.isSameOrAfter(obData.price_valid_at)))
        );
      })
      .orderBy("price_valid_at", "desc")
      .first()
      .value();
  }
}

export default function (
  obModel: Product | ProductData | Partial<ProductData>
) {
  const obProduct = new ProductHelper(obModel);

  return { obProduct };
}
